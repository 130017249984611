import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { LandingPage } from "./components/landingPage";
import { PhotoLibrary } from "./components/photoLibrary";
import JsonData from "./data/data.json";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    window.history.scrollRestoration = "manual";
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/gallery"
          element={<PhotoLibrary data={JsonData.PhotoGallery} />}
        />
        <Route
          exact
          path="/"
          element={<LandingPage landingPageData={JsonData} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;

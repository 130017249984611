import React from "react";
import { Fade } from "react-awesome-reveal";

export const Quote  = (props) => {
  return (
    <div id="quote" className="text-center">
      <div className="container" style={{ marginTop: "40px", marginBottom: "50px" }}>
        <div >
            <Fade direction={"up"} triggerOnce>

          <h1 style={{ color: "white", fontSize: "45" }}>"Join us in our mission to reduce waste and promote sustainable manufacturing practices."</h1>
</Fade>
          <p style={{ color: "white", fontSize: "25",marginTop: "30" }}>
        
              <strong>Let's create a better future with 3D printing technology.</strong>
            
          </p>

          </div>
       
      </div>
    </div>
  );
};

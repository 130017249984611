import React, { useEffect } from "react"; // Importing useEffect from React
import { Fade } from "react-awesome-reveal";
import './faq.css'; // Assuming your common styles are imported here

export const Faq = (props) => {
  useEffect(() => {
    const questions = document.querySelectorAll(".faq-question");

    questions.forEach(question => {
      question.addEventListener("click", event => {
        const active = document.querySelector(".faq-question.active");
        if(active && active !== question ) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if(question.classList.contains("active")){
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      })
    });

    // Clean up function
    return () => {

      questions.forEach(question => {
        question.removeEventListener("click", event => {
          // Your event listener logic here
        });
      });
    };
  }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount

  return (
    <div id="faq" className="text-center">
      <div className="container">



        
        <div className=" col-md-offset-1 section-title">
        <Fade direction={"up"} triggerOnce>

          <h2>FAQ</h2>
          <p style={{ color: "black", fontSize: "25" }}>
            <u>
              <strong>Common questions about 3d Printing.</strong>
            </u>
          </p>
</Fade>      
        </div>
        {/* <div className="row text-left">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-md-12 w-50"
                  style={{ marginBottom: "10px" }}
                >
                  <i className={d.icon}></i>
                  <div>
                    <h3 style={{ color: "black" }}>
                      {i + 1}. {d.title}
                    </h3>
                  </div>
                  <p style={{ color: "gray", fontSize: "15" }}>
                    <strong>{d.text}</strong>
                  </p>
                </div>
              ))
            : "Loading..."}
        </div>
 */}



<div className="container">
        <div className="faq-item">


          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  style={{ 
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "20px",
                  boxShadow: "0 5px 10px 0 rgb(0,0,0,0.25)",
                  margin: "20px 0" }}

                >
                  <div className="faq-question">
                  
                      {d.title}
                    
                  </div>
                  <div className="faq-answercont">
                    <div className="faq-answer">
                   
                        {d.text}
                     
                    </div>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
        </div>











      </div>
    </div>
  );
}

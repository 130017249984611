import React from "react";
import { Navigation } from "./navigation";
import LightGallery from "lightgallery/react";
import "./photoLibrary.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgFullScreen from "lightgallery/plugins/fullscreen";

export const PhotoLibrary = (props) => {
  return (
    <>
      <Navigation isNavigate/>
      <div className="photo-lib-container">
        <div className="row ">
          <div className="col-12">
            <h2 className="text-center my-4 photo-gallery-title">
              Photo Gallery
            </h2>
          </div>
        </div>
        <div className="light-gallery">
          <LightGallery
            speed={500}
            mode="lg-fade"
            download={false}
            plugins={[lgThumbnail, lgFullScreen, lgZoom]}
            zoom
            width="100%"
          >
            {props.data &&
              props.data.map((d, i) => (
                <a href={d.largeImage} className="photo-library-image">
                  <img
                    alt={d.title ? d.title : `img-${i}`}
                    className="gallery-items"
                    src={d.largeImage}
                  />
                </a>
              ))}
          </LightGallery>
        </div>
      </div>
    </>
  );
};

import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import SmoothScroll from "smooth-scroll";
import WhatsAppButton from "./WhatsAppButton";
// import { About } from "./about";
import { Faq } from "./faq";
import { Getquote } from "./getquote";
// import FAQ from './faq'; // Adjust the path if necessary

// import { Gallery } from "./gallery";
import { Header } from "./header";
import { Navigation } from "./navigation";
//import { Services } from "./services";
import { Quote } from "./quote";

import Footer from "./Footer";
import './landingPage.css'

const About = lazy(() => import("./about"));
const Services = lazy(() => import("./services"));
const Gallery = lazy(() => import("./gallery"));

const whatsappStickyStyle = {
  position: "fixed",
  bottom: "20px", // Adjust the distance from the bottom as needed
  left: "calc(100% - 100px)", // Adjust the distance from the left as needed
  backgroundColor: "rgb(141 141 141 / 35%)", // Background color of the icon
  borderRadius: "50%", // To make it a circular shape
  padding: "8px", // Adjust padding as needed
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: Add a subtle shadow
  cursor: "pointer", // Optional: Change cursor on hover
};

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
export const LandingPage = ({ landingPageData }) => {
  return (
    <div className="root">
      
      <Suspense fallback={<div>Loading...</div>}>
        <Navigation />
        {/* <Fade direction={"right"} triggerOnce> */}
          <Header data={landingPageData.Header} />
        {/* </Fade> */}
        {/* <Features data={landingPageData.Features} /> */}
        {/* <Fade direction={"left"} triggerOnce> */}
          <About data={landingPageData.About} />{" "}
        {/* </Fade> */}
        {/* <Fade direction={"right"} triggerOnce> */}
          <Services data={landingPageData.Services} />
        {/* </Fade> */}
        {/* <Fade direction={"left"} triggerOnce> */}
          <Gallery data={landingPageData.Gallery} />
        {/* </Fade> */}
        {/* <Review /> */}
        {/* <Fade direction={"left"} triggerOnce> */}
          <Quote data={landingPageData.Quote} />
        {/* </Fade> */}
        {/* <Fade direction={"right"} triggerOnce> */}
          <Faq data={landingPageData.Faq} />
        {/* </Fade> */}
        {/* <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />  */}
        {/* <Fade direction={"left"} triggerOnce> */}
          <Getquote data={landingPageData.Getquote} />
        {/* </Fade> */}
        {/* <WhatsAppButton className="whatsapp-button" phoneNumber="YOUR_PHONE_NUMBER" message="YOUR_DEFAULT_MESSAGE" /> */}
        <div style={whatsappStickyStyle}>
          <WhatsAppButton phoneNumber="+919909054460" message="hello there" />
        </div>
        <Footer data={landingPageData.Getquote} />{" "}
        {/* Include the Footer component here */}
        <ToastContainer />
      </Suspense>
    </div>
  );
};

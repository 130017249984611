import React from "react";

import { TypeAnimation } from "react-type-animation";
import 'animate.css';
export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-0 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                </h1>
                <p>
                  <TypeAnimation
                    sequence={[
                      "Shaping Your Imagination",
                      1000,
                      "Shaping Your Innovation",
                      1000,
                      "Shaping Your Creativity",
                      1000,
                    ]}
                    wrapper="span"
                    speed={300}
                    repeat={Infinity}
                  />
                  {/* into Reality. */}
                </p>
                <a href="#getquote"className="btn btn-custom btn-lg page-scroll animate__animated animate__shakeY"
                >
                  Get Quote
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";

// import InconButton from "./IconButton";
import { FaGithub } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaMeta } from "react-icons/fa6";
import {IoLogoYoutube } from"react-icons/io";

import { Background } from "react-parallax";

const Footer = (props) => {
  return (
    <footer id="footer" style={{ backgroundColor: "#000000" }}>
      <div className="footer">
        <table
          border="0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <tr>
            <td>
              <div class="wrapper">
                <a class="button" href={props.data ? props.data.facebook : "/"}>
                  <div class="icon">
                    {/* <i class="fa fa-facebook"></i> */}
                    <FaMeta size={40} style={{ marginTop: "10px" }} />
                  </div>
                  <span>Meta</span>
                </a>
              </div>
            </td>

            <td>
              <div class="wrapperi">
                <a class="buttoni" href={props.data ? props.data.youtube : "/"}>
                  <div class="iconi">
                    {/* <i GrInstagram></i> */}
                    <GrInstagram size={40} style={{ marginTop: "10px" }} />
                  </div>
                  <span>Instagram</span>
                </a>
              </div>
            </td>
            <td>
              <div class="wrapperyt">
                <a class="buttonyt" href={props.data ? props.data.youtube_a : "/"}>
                  <div class="iconyt">
                    {/* <i GrInstagram></i> */}
                    <IoLogoYoutube size={40} style={{ marginTop: "10px" }} />
                  </div>
                  <span>YouTube</span>
                </a>
              </div>
            </td>
          </tr>
        </table>

        {/* <div class="wrapper">
      <div class="button">
        <div class="icon">
        <a href={props.data ? props.data.facebook : "/"}>
          <i class="fab fa-facebook-f"></i> */}
        {/* <i className="fa fa-facebook fa-3x " style={{marginRight:"20px" , marginLeft:"20px", margintop:"20px", marginBottom:"20px"}}></i> */}
        {/* </a>
        </div>
        <span>Facebook</span>
      </div>
     
              


      <div class="button">
        <div class="icon">
          <i class="fab fa-instagram-f"></i> */}

        {/* <i className="fa fa-instagram fa-3x" style={{marginRight:"20px" , marginLeft:"20px", margintop:"20px", marginBottom:"20px"}}></i> */}
        {/* </div>
        <span>Instagram</span>
      </div>
           
          </div> */}
        <h3 style={{ marginTop: "40px", color: "white" }}>
          <u>Contact Info</u>
        </h3>

        <p style={{ marginTop: "2px" }}>
          <a
            href="tel:+91 9909054460"
            target="_blank"
            style={{ color: "white" }}
          >
            <span>
              <i className="fa fa-phone"></i>
            </span>
            &nbsp;
            {props.data ? props.data.phone : "loading"}
          </a>
        </p>
        <p style={{ marginTop: "5px" }}>
          <a
            href="mailto:vmaharajwala@gmail.com"
            target="_blank"
            style={{ color: "white" }}
          >
            <span>
              <i className="fa fa-envelope-o"></i>
            </span>
            &nbsp;
            {props.data ? props.data.email : "loading"}
          </a>
        </p>
        <p style={{ marginTop: "5px" }}>
          <a
            href="https://maps.app.goo.gl/K79uKt2ugdddaJ7C8"
            target="_blank"
            style={{ color: "white" }}
          >
            <span>
              <i className="fa fa-map-marker"></i>
            </span>
            &nbsp;
            {props.data ? props.data.address : "loading"}
          </a>
        </p>

        <img
          src="img/newlogo.svg"
          style={{ width: "200px", height: "150px", marginTop: "25px" }}
          alt="logo"
        ></img>
        <p style={{ marginTop: "-10px", fontSize: "13px" }}>
          TRANSFORMING IDEAS INTO REALITY
        </p>
        <div className="copyright">
          <p>
            &copy; {new Date().getFullYear()} Acquibots 3D Printing. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

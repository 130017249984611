import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { InfinitySpin } from "react-loader-spinner";
import { Bounce, toast } from "react-toastify";
import { Fade } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import "./getquote.css";

const initialState = {
  name: "",
  email: "",
  message: "",
  phoneNumber: "",
  file: null, // Add file state
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  border: "#a2b6c863 1px dashed",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const Getquote = (props) => {
  const [{ name, email, message, phoneNumber, file }, setState] =
    useState(initialState);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      setState((prevState) => ({ ...prevState, file: files[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const clearState = () => setState({ ...initialState });

  // Show Toast on Email Confirmation
  const sendToast = (message, error = false) => {
    if (!error) {
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
  };

  //Button Click Function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // const formData = new FormData();
    // formData.append("name", name);
    // formData.append("email", email);
    // formData.append("message", message);
    // formData.append("phoneNumber", phoneNumber);
    // formData.append("file", file); // Append file to formData

    // emailjs
    //   .send(
    //     "service_ilwx1ki",
    //     "template_7lp15jh",
    //     {
    //       from_name: name,
    //       to_name: "Vrushank",
    //       message: message,
    //       reply_to: email,
    //       cc_to: email,
    //       phone_number: phoneNumber,
    //       file: file,
    //     },
    //     "NIAp2z7wUR7AOvtDY"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //       setLoading(false);
    //       document.getElementById("from_name").value = "";
    //       document.getElementById("reply_to").value = "";
    //       document.getElementById("phoneNumber").value = "";
    //       document.getElementById("message").value = "";
    //       document.getElementById("file").value = "";

    //       sendToast("Email Sent Successfully!");
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       sendToast("Error While Sending Email!", true);
    //     }
    //   );
    const formData = new FormData();
    formData.append("to", email);
    formData.append("from", "vrushankm@acquibots3d.com");
    formData.append("subject", "Inquire From Website");
    formData.append(
      "html",
      `Thank you! ${name}, ${email}, ${message} ${phoneNumber} `
    );
    if (files && files.length > 0) {
      formData.append("file", files[0]);
    }
    try {
      await axios.post(
        "https://us-central1-ultimatebottest.cloudfunctions.net/sendEmailWithAttachment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      sendToast("Email sent successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error sending email:", error);
      sendToast("Failed to send email. Please try again later.", true);
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/*": [
        ".dwg",
        ".dxf",
        ".step",
        ".iges",
        ".stl",
        ".obj",
        ".3dm",
        ".sldprt",
        ".sldasm",
        ".ipt",
        ".iam",
        ".prt",
        ".asm",
        ".catpart",
        ".catproduct",
        ".prt",
        ".asm",
      ],    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length === 0) {
        sendToast("Upload File in image/Cad format under 20MB", true);
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
    maxSize: 20000000,
  });
  const thumbs = files.map((file) => {
    return (
      <div key={file.name}>
  {["image/jpeg", "image/png"].includes(file.type) || file.name.match(/\.(dwg|dxf|step|iges|stl|obj|3dm|sldprt|sldasm|ipt|iam|prt|asm|catpart|catproduct)$/i) ? (
          <img src={file.preview} alt={file.name} style={{ width: "100px" }} />
        ) : (
          <span>{file.name}</span>
        )}
      </div>
    );
  });

  return (
    <div>
      <div id="getquote">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <Fade direction={"up"} triggerOnce>
                  <h2>Get In Touch</h2>
                </Fade>
                <Fade direction={"up"} triggerOnce>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </Fade>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="from_name"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="email"
                        id="reply_to"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Enter phone number"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div class="card" style={{ backgroundColor: "white" }}>
                    <div
                      class="drop_box"
                      style={{
                        textAlign: "center",
                        border: "3px dotted grey",
                      }}
                    >
                      <header>
                        <strong>
                          <h4 style={{ color: "grey" }}> Select File here</h4>
                        </strong>
                      </header>
                      <p style={{ color: "grey" }}>
                          Files Supported: STL, STEP, DXF & OTHER ALL CAD FILE
                          FORMATS
                        </p>
                      {/* <input type="file" hidden accept=".doc,.docx,.pdf" id="fileID" style="display:none;"/> */}

                      <button
    style={{
        marginBottom: "15px",
        backgroundColor: "rgb(79 79 79)",
        borderRadius: "30px",
        color: "white",
        cursor: "pointer", // Change cursor on hover
        transition: "background-color 0.3s", // Smooth transition
        border: "none", // Remove button border
        padding: "10px 20px", // Add padding for better clickability
    }}
    onMouseEnter={(e) => {
        e.target.style.backgroundColor = "rgb(9 9 9)"; // Change color on hover
    }}
    onMouseLeave={(e) => {
        e.target.style.backgroundColor = "rgb(79 79 79)"; // Revert to original color
    }}
>
    Choose File
</button>
                    </div>
                  </div>
                </div>
                {files.length !== 0 && (
                  <aside style={thumbsContainer}>{thumbs}</aside>
                )}

                <div id="success"></div>
                {loading ? (
                  <InfinitySpin
                    visible={loading}
                    color="#ffffff"
                    width="90"
                    height="40"
                    ariaLabel="infinity-spin-loading"
                  />
                ) : (
                  <button type="submit" className="btn btn-custom btn-lg">
                    <div>Send Message</div>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// WhatsAppButton.jsx
import React from 'react';
// import whatsappIcon from "..//img/whatsapp.png"; // Import your WhatsApp button image

const WhatsAppButton = ({ phoneNumber, message }) => {
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (

    <img src="..//img/whatsapp.png" width="50px" height="50px" alt="WhatsApp"  onClick={handleWhatsAppClick} />
  );
};

export default WhatsAppButton;

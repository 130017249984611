  import React,{ useState, useEffect } from "react";
  import { useNavigate } from "react-router-dom";

  export const Navigation = ({ isNavigate = false }) => {
    
    const [scrolled, setScrolled] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const navigateToHomePage = (route) => {
      if (isNavigate) {
        navigate(`/${route}`);
      }
    };
    return (
      <nav id="menu" 
      className={`navbar navbar-default navbar-fixed-top ${
        scrolled ? "scrolled" : ""
      }`}
    >

        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>

            {/* <a className="navbar-brand page-scroll" href="#page-top">
              Acquibots 3d Printing
            </a>{" "} */}

            {/* csdgjuk */}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <a href="#page-top" className="page-scroll" onClick={() => navigateToHomePage("#page-top")}>
            <img 
            src="img/newlogo.svg" 
            style={{  width: '100px', height: 'auto', filter: 'brightness(150%)' }}/>
            </a>

            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  href="#page-top"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#page-top")}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#about")}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#services")}
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#portfolio"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#portfolio")}
                >
                  Gallery
                </a>
              </li>
              <li>
                <a
                  href="#faq"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#faq")}
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="#getquote"
                  className="page-scroll"
                  onClick={() => navigateToHomePage("#getquote")}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  };
